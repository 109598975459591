export const PERMISSION_ACTION = {
  ACCESS_FUNDING_SOURCES: "ACCESS_FUNDING_SOURCES",
  ACCESS_STAKEHOLDERS: "ACCESS_STAKEHOLDERS",
  ADVANCED_REPORT: "ADVANCED_REPORT",
  ADVANCED_REPORT_DEVELOPER: "ADVANCED_REPORT_DEVELOPER",
  ADVANCED_REPORT_LENDER: "ADVANCED_REPORT_LENDER",
  AGREEMENT_MANAGEMENT: "AGREEMENT_MANAGEMENT",
  AI_CHATBOT: "AI_CHATBOT",
  ALLOW_MULTIPLE_UNFUNDED_BORROWER_DRAWS:
    "ALLOW_MULTIPLE_UNFUNDED_BORROWER_DRAWS",
  APPROVE_DOCUMENTS: "APPROVE_DOCUMENTS",
  ASSIGN_DOCUMENTS: "ASSIGN_DOCUMENTS",
  ASSIGN_MULTIPLE_LINE_ITEM_COST_CODES: "ASSIGN_MULTIPLE_LINE_ITEM_COST_CODES",
  AUTO_ALLOCATE: "AUTO_ALLOCATE",
  AUTO_CALCULATE_RETAINAGE: "AUTO_CALCULATE_RETAINAGE",
  AVID_AP_INTEGRATION: "AVID_AP_INTEGRATION",
  BORROWER_PORTAL: "BORROWER_PORTAL",
  CONFIGURE_ORGANIZATION: "CONFIGURE_ORGANIZATION",
  CREATE_DRAW: "CREATE_DRAW",
  CREATE_EDIT_ORGANIZATION: "CREATE_EDIT_ORGANIZATION",
  CREATE_PROJECT: "CREATE_PROJECT",
  DELETE_DOCUMENTS: "DELETE_DOCUMENTS",
  DELETE_DRAW: "DELETE_DRAW",
  DELETE_ORGANIZATION: "DELETE_ORGANIZATION",
  DELETE_PROJECT: "DELETE_PROJECT",
  DEPENDENCY_TRACKING: "DEPENDENCY_TRACKING",
  DO_NOT_VOID_DRAW_APPROVALS: "DO_NOT_VOID_DRAW_APPROVALS",
  DOWNLOAD_DOCUMENT: "DOWNLOAD_DOCUMENT",
  DRAW_ASSESSMENT_QUESTIONS_FORM: "DRAW_ASSESSMENT_QUESTIONS_FORM",
  DRAW_OVERVIEW_PAGE: "DRAW_OVERVIEW_PAGE",
  DRAW_SIGNATORY: "DRAW_SIGNATORY",
  EDIT_AMOUNT_REQUESTED: "EDIT_AMOUNT_REQUESTED",
  EDIT_BUDGET: "EDIT_BUDGET",
  EDIT_DRAW_STATUS: "EDIT_DRAW_STATUS",
  EDIT_ORIGINAL_BUDGET_AMOUNT: "EDIT_ORIGINAL_BUDGET_AMOUNT",
  EDIT_PROJECT_SETTINGS: "EDIT_PROJECT_SETTINGS",
  EDIT_USER_ACCESS: "EDIT_USER_ACCESS",
  ENABLE_TRACKED_COSTS_ACROSS_VENDORS: "ENABLE_TRACKED_COSTS_ACROSS_VENDORS",
  GENERATE_API_TOKEN: "GENERATE_API_TOKEN",
  HUD_FEATURES: "HUD_FEATURES",
  IMPORT_DRAW_SUMMARY: "IMPORT_DRAW_SUMMARY",
  INCLUDE_CODE_INFO_FOR_MRI: "INCLUDE_CODE_INFO_FOR_MRI",
  INSPECTION_REPORT_WORKFLOW: "INSPECTION_REPORT_WORKFLOW",
  INVOICE_SUMMARY_TAB: "INVOICE_SUMMARY_TAB",
  JOB_COST_CODES: "JOB_COST_CODES",
  LIMIT_TRACKED_AGREEMENT_WARNINGS: "LIMIT_TRACKED_AGREEMENT_WARNINGS",
  LINE_ITEM_DRAWER: "LINE_ITEM_DRAWER",
  LOCK_PREVIOUS_DRAWS: "LOCK_PREVIOUS_DRAWS",
  MAKE_PROJECT_BUDGET_ADJUSTMENTS: "MAKE_PROJECT_BUDGET_ADJUSTMENTS",
  MANAGE_USER: "MANAGE_USER",
  NEXUS_INTEGRATION: "NEXUS_INTEGRATION",
  PAYMENT_TRACKING: "PAYMENT_TRACKING",
  PROJECT_FORECASTING: "PROJECT_FORECASTING",
  PULL_DATA_FROM_YARDI: "PULL_DATA_FROM_YARDI",
  PUSH_TO_BILL: "PUSH_TO_BILL",
  PUSH_TO_MRI: "PUSH_TO_MRI",
  PUSH_TO_QUICK_BOOKS_ONLINE: "PUSH_TO_QUICK_BOOKS_ONLINE",
  REPORT_GENERATION: "REPORT_GENERATION",
  RULES_REDESIGN_CLERICAL: "RULES_REDESIGN_CLERICAL",
  RUN_REPORT: "RUN_REPORT",
  SAVE_TABLE_VIEWS: "SAVE_TABLE_VIEWS",
  SEND_RETAINAGE_TO_ACCOUNTS_PAYABLE: "SEND_RETAINAGE_TO_ACCOUNTS_PAYABLE",
  SHOW_INVOICE_RETAINAGE: "SHOW_INVOICE_RETAINAGE",
  SHOW_PARSED_FIELDS: "SHOW_PARSED_FIELDS",
  SKIP_MFA: "SKIP_MFA",
  STAMP_DOCUMENTS: "STAMP_DOCUMENTS",
  SUBCONTRACTOR_FORM: "SUBCONTRACTOR_FORM",
  SUBMIT_DRAW: "SUBMIT_DRAW",
  SUPER_LINE_ITEMS: "SUPER_LINE_ITEMS",
  TASK_MANAGEMENT: "TASK_MANAGEMENT",
  TEAM_MANAGEMENT: "TEAM_MANAGEMENT",
  TRACK_COST_TO_AGREEMENTS: "TRACK_COST_TO_AGREEMENTS",
  TURN_OFF_TILE_VIEW: "TURN_OFF_TILE_VIEW",
  UPDATE_DOCUMENT: "UPDATE_DOCUMENT",
  UPDATE_REQUESTED_AMOUNT_WITH_DOCUMENTATION:
    "UPDATE_REQUESTED_AMOUNT_WITH_DOCUMENTATION",
  UPLOAD_DOCUMENT: "UPLOAD_DOCUMENT",
  USE_COBRANDING: "USE_COBRANDING",
  USE_ENHANCED_LINE_ITEM_REPORTING: "USE_ENHANCED_LINE_ITEM_REPORTING",
  USER_PROJECT_ACCESS: "USER_PROJECT_ACCESS",
  USES_OF_FUNDS: "USES_OF_FUNDS",
  VENDOR_COST_CODES: "VENDOR_COST_CODES",
  VIEW_ALL_PROJECTS: "VIEW_ALL_PROJECTS",
  VIEW_ALL_TEAM_PROJECTS: "VIEW_ALL_TEAM_PROJECTS",
  VIEW_ORGANIZATIONS: "VIEW_ORGANIZATIONS",
  VISION_DELTEK_AP_INTEGRATION: "VISION_DELTEK_AP_INTEGRATION",
  YARDI_INTEGRATION: "YARDI_INTEGRATION",
  YARDI_JOB_COST_MODULE: "YARDI_JOB_COST_MODULE",
  YARDI_PULL_INDIVIDUALIZE_JOURNAL_ENTRIES:
    "YARDI_PULL_INDIVIDUALIZE_JOURNAL_ENTRIES",
};
